html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.theme-page-container {
    margin: auto;
    width: 50%;
    height: 100%;
}

.theme-page-header {
    position: sticky;
    /* Safari */
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    /* background: #3f51b5; */
    z-index: 1000;
}

.theme-toolbar-spacer {
    flex: 1 1 auto;
}

.theme-page-body {
    /* margin-bottom: 55px; */
    padding: 16px;
}

.theme-card {
    margin-top: 10px;
}

.theme-card-header {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
}

.theme-card-footer {
    font-weight: 300;
    font-size: 13px;
    margin-top: 20px;
}

.theme-page-title {
    text-align: center;
}

.theme-page-title h2 {
    font-weight: 500;
    color: #3f51b5;
}

.theme-card-item {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    /*for links incase used*/
    color: rgba(0, 0, 0, 0.87);
    /*for links incase used*/
    cursor: pointer;
    /*for desktop*/
}

.theme-card-item-content {
    flex: 1 1 auto;
}

.theme-card-item-thumbnail-icon {
    color: #3f51b5;
    margin-right: 10px;
}

.theme-card-item-link-icon {
    color: #3f51b5;
}

.theme-feedback {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
    font-weight: 400;
    font-size: 13px;
}

.theme-form-field {
    width: 100%;
}

.theme-spinner {
    width: 80%;
    margin: auto;
}

.theme-link-text {
    text-decoration: none;
    color: #3f51b5;
}

.theme-link-text:active {
    color: #3f51b5;
}

@media only screen and (max-width: 450px) {
    .theme-page-container {
        width: 100%;
    }
}